export const formatDateForBackend = (date) => {
    const date_obj = new Date(date);
    const utcDate = new Date(
      Date.UTC(
        date_obj.getFullYear(),
        date_obj.getMonth(),
        date_obj.getDate(),
        date_obj.getHours(),
        date_obj.getMinutes(),
        date_obj.getSeconds()
      )
    );
    const year = utcDate.getUTCFullYear();
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = utcDate.getUTCDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  export const parseDateString = (dateString) => {
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  };

  export const getDefaultRequestBody = () => {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - 30);
  
    return {
      start_date: formatDateForBackend(startDate),
      end_date: formatDateForBackend(today),
      category: ["Organic", "Paid"],
      performance_value: [5, 4, 3, 2, 1, 0],
      type: ["Image", "Video", "Audio", "PDF", "Blog", "Document", "Link"],
      channel: ["Facebook", "Instagram", "Google_Ads", "Google_Analytics", "Linkedin", "Reddit", "TradeDesk", "Offline_Media", "Youtube"],
      status: ["Active", "Enabled", "Available", "Published"],
    };
  }
  
  export const getShadeInfo = (performance_value, isHubview = false) => {
    const performance_number = parseInt(performance_value, 10);
    if(isHubview && performance_number === 0){
        return { color: "transparent", label: "Null" }
    }
    switch (performance_number) {
      case 5:
        return { color: "#cf0000", label: "Very_Low" };
      case 4:
        return { color: "#ff6344", label: "Low" };
      case 3:
        return { color: "#FFFF00", label: "Average" };
      case 2:
        return { color: "#b2ff6b", label: "Good" };
      case 1:
        return { color: "#00a318", label: "Excellent" };
      case 0:
        return { color: "#808080", label: "Unknown" };
      default:
        return { color: "transparent", label: "Null" };
    }
  };

  export const renderBadge = (performance_value) => {
    const performance_number = parseInt(performance_value, 10);
    switch (performance_number) {
      case 5:
        return (
          <span
            className="badge ms-1"
            style={{backgroundColor: "#b30000", color: "black"}}>
            Very Low
          </span>
        );
      case 4:
        return (
          <span
            className="badge ms-1"
            style={{backgroundColor: "#ff0000", color: "black"}}>
            Low
          </span>
        );
      case 3:
        return (
          <span
            className="badge ms-1"
            style={{backgroundColor: "#FFD900", color: "black"}}>
            Average
          </span>
        );
      case 2:
        return (
          <span
            className="badge ms-1"
            style={{backgroundColor: "#3CB371", color: "black"}}>
            Good
          </span>
        );
      case 1:
        return (
          <span
            className="badge ms-1"
            style={{backgroundColor: "#008000", color: "black"}}>
            Excellent
          </span>
        );
      case 0:
        return (
          <span
            className="badge ms-1"
            style={{backgroundColor: "#808080", color: "black"}}>
            Unknown
          </span>
        );
      default:
        return "Unavailable";
    }
  };

  export const formatKeys = (str) => {
    return str
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/[_+]/g, ' ')       // Replace underscores and pluses with space
      .split(' ')                  // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(' ');                  // Join the words back into a single string
  };