import React from 'react';
import useCustomDispatch from '../utils/useCustomDispatch';
import { formatKeys } from '../utils/utils';

function Asset_Filters({ 
  selectedCategory, 
  selectedPlatform, 
  selectedType, 
  selectedStatus,
  selectedPerformance, 
  categories, 
  platforms, 
  types,
  Status, 
  performance,
  getShadeInfo, 
  categoryCounts = {}, 
  platformCounts = {}, 
  typeCounts = {},
  statusCounts = {},
  performanceCounts = {},
  totalCounts = {},
  disabledPlatforms,
  // ETFs,
  // Tiers
}) {
  const {
    handleCategoryChange,
    handlePlatformsChange,
    handleTypesChange,
    handleStatusChange,
    handlePerformanceChange,
  } = useCustomDispatch();
  const formatPlatformName = (platform) => {
    switch (platform) {
      case "Offline_Media":
        return "Offline";
      case "Google_Ads":
        return "Google Ads";
      case "Google_Analytics":
        return "Google Analytics";
      default:
        return platform;
    }
  };

  return (
    <div className={"d-flex flex-wrap mt-2"}>
      {/* CategoryDropdown */}
      <div className="dropdown pe-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="categoryDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Categories
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="categoryDropdown">
          {categories.map((category, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedCategory.includes(category)}
                  onChange={() => handleCategoryChange(category)}
                />
                {`${category} (${categoryCounts[category] || 0}/${totalCounts[category] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* PlatformDropdown  */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="channelDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Platforms
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="channelDropdown">
          {platforms.map((platform, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedPlatform.includes(platform)}
                  onChange={() => handlePlatformsChange(platform)}
                  disabled={disabledPlatforms.includes(platform)}
                />
               {`${formatPlatformName(platform)} (${platformCounts[platform] || 0}/${totalCounts[platform] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* TypeDropdown */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="typeDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Types
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="typeDropdown">
          {types.map((type, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedType.includes(type)}
                  onChange={() => handleTypesChange(type)}
                />
                {`${type} (${typeCounts[type] || 0}/${totalCounts[type] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* StatusDropdown */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="statusDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Status
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="statusDropdown">
          {Status.map((status, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedStatus.includes(status)}
                  onChange={() => handleStatusChange(status)}
                />
                {`${status} (${statusCounts[status] || 0}/${totalCounts[status] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* PerformanceDropdown  */}
      <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="shadeDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Performance
        </button>
        <ul className="dropdown-menu bg-black text-white" aria-labelledby="shadeDropdown">
          {performance.map((shade, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  checked={selectedPerformance.includes(shade)}
                  onChange={() => handlePerformanceChange(shade)}
                />
                <span
                  className="shade-circle"
                  style={{backgroundColor: getShadeInfo(shade).color}}></span>
                {`${formatKeys(getShadeInfo(shade).label)} (${performanceCounts[getShadeInfo(shade).label] || 0}/${totalCounts[getShadeInfo(shade).label] || 0})`}
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* ETFsDropdown */}
      {/* <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="ETFsDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          ETFs
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="ETFsDropdown">
          {ETFs.map((etf, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  disabled={true}
                  // checked={selectedStatus.includes(status)}
                  // onChange={() => handleStatusChange(status)}
                />
                {`${etf}`}
              </label>
            </li>
          ))}
        </ul>
      </div> */}
      {/* TiersDropdown */}
      {/* <div className="dropdown mx-1 mt-1">
        <button
          className="btn btn-black dropdown-toggle text-white border-secondary py-1"
          type="button"
          id="TiersDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          Tiers
        </button>
        <ul
          className="dropdown-menu bg-black text-white"
          aria-labelledby="TiersDropdown">
          {Tiers.map((tier, index) => (
            <li key={index}>
              <label
                className="dropdown-item text-white"
                onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  className="me-2"
                  disabled={true}
                  // checked={selectedStatus.includes(status)}
                  // onChange={() => handleStatusChange(status)}
                />
                {`${tier}`}
              </label>
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
}

export default Asset_Filters;
