import React, {useState, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {useNavigate, useLocation} from "react-router-dom";
import Navbar from "./elements/Navbar";
import Footer from "../../components/footer";
import Sidebar from "./elements/Sidebar";
import API from "./API_DataFeeds/API";
import Assets from "./Assets/Assets";
import Details from "./Assets/DetailView/Details";
import AdminConsole from "./AdminConsole/AdminConsole";
import AssetUpload from "./OfflineMedia/AssetUpload";
import JourneyMap from "./JourneyMap/JourneyMap";
import EditJourneyMap from "./JourneyMap/EditJourneyMap";
// import IntegrationMap from "./IntegrationMap/IntegrationMap";
// import AssetView from "./IntegrationMap/AssetView";
import Profile from "./Profile";
import config from "../../config.json";
import axios from "axios";
// import whiteLabel from "../../assets/white_label.json";
import "../../Styles/style.css";

function HomePage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedComponent, setSelectedComponent] = useState(
    sessionStorage.getItem("selectedComponent") || "Assets"
  );
  const set_signed_cookie_url = `${config.serviceUrl}/auth/set_signed_cookie`;
  // Cookie set start
  // eslint-disable-next-line
  const [isCookie, setIsCookie] = useState(false);
  const set_signed_cookie = async () => {
    try {
      const authToken = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      };
      const body = {
        client_id: props.user.client_id,
      };
      // eslint-disable-next-line
      const cookie_response = await axios.post(set_signed_cookie_url, body, {
        headers: config.headers,
        withCredentials: true,
      });
      setIsCookie(true);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    set_signed_cookie();

    // Timer to refresh the cookie every 13 minutes
    const cookieRefreshTimer = setInterval(() => {
      set_signed_cookie();
    }, 780000);

    // Add event listener for visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        set_signed_cookie();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    
    // Timer will be cleaned if component unmounts.
    return () => clearInterval(cookieRefreshTimer);
    // eslint-disable-next-line
  }, []);
  // Cookie set end

  // Sidebar
  const [isSideBar, setIsSideBar] = useState(1);

  // Navbar
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.screenY);
  const [semanticSearchText, setSemanticSearchText] = useState("");

  const handleSearch = (text) => {
    setSemanticSearchText(text);
  };

  const handleScroll = () => {
    // use useRef instead or something else that is react specific
    const currentScrollPos = document.getElementById("myscroll").scrollTop;
    setIsNavbarVisible(
      currentScrollPos < prevScrollPos || currentScrollPos <= 0
    );
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    const scrollElement = document.getElementById("myscroll");
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line
  }, [prevScrollPos]);

  const toggleSidebar = () => {
    setIsSideBar(!isSideBar);
  };

  const handleNavigation = (component) => {
    const componentPaths = {
      Assets: "/assets",
      API: "/api",
      AdminConsole: "/adminconsole",
      AssetUpload: "/assetupload",
      Details: "/assets/details/:id",
      JourneyMap: "/journeymap",
      AddSteps: "/journeymap/addsteps",
      // IntegrationMap: "/integrationmap",
      // AssetView: "/integrationmap/assetview/:id",
      Profile: "/profile"
    };
    const path = componentPaths[component];
    if (path) {
      setSelectedComponent(component);
      navigate(path);
      sessionStorage.setItem("selectedComponent", component);
    }
  };

  useEffect(() => {
    handleNavigation(location.pathname.replace("/assets", ""));
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("selectedComponent");
    };
  }, []);

  return (
    <main className="content">
      <div className="dashboard-image"></div>
      <div className="container-fluid vh-100">
        <Navbar
          user={props.user}
          isNavbarVisible={isNavbarVisible}
          handleLogout={props.handleLogout}
          isSideBar={isSideBar}
          toggleSidebar={toggleSidebar}
          onSearch={handleSearch}
          isDetailsPage={location.pathname === "/assets"}
        />
        <div className="row vh-100">
          <Sidebar
            selectedComponent={selectedComponent}
            handleNavigation={handleNavigation}
            isSideBar={isSideBar}
            toggleSidebar={toggleSidebar}
          />
          <div
            id="myscroll"
            className={`pe-0 pt-2 ${
              isSideBar ? "col-9 col-md-9 col-lg-10" : "col"
            } overflow-auto h-100`}>
            {isCookie && (
              <>
                {location.pathname === "/assets" && (
                  <Assets
                    user={props.user}
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    semanticSearchText={semanticSearchText}
                  />
                )}
                {location.pathname === "/api" && <API user={props.user} />}
                {location.pathname === "/adminconsole" && (
                  <AdminConsole
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    user={props.user}
                  />
                )}
                {location.pathname === "/assetupload" && (
                  <AssetUpload
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    user={props.user}
                  />
                )}
                {location.pathname === "/journeymap" && (
                  <JourneyMap
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    user={props.user}
                  />
                )}
                {location.pathname === "/journeymap/addsteps" && (
                  <EditJourneyMap
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    user={props.user}
                  />
                )}
                {/* {location.pathname === "/integrationmap" && (
                  <IntegrationMap
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    user={props.user}
                  />
                )}
                {location.pathname.includes("/integrationmap/assetview/") && (
                  <AssetView
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    user={props.user}
                  />
                )} */}
                {location.pathname.includes("/assets/details/") && (
                  <Details
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    user={props.user}
                    toggleSidebar={toggleSidebar}
                  />
                )}
                {location.pathname === "/profile" && (
                  <Profile
                    isNavbarVisible={isNavbarVisible}
                    isSideBar={isSideBar}
                    user={props.user}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="position-fixed w-100" style={{bottom:"0px", zIndex:"999"}}>
          <Footer />
        </div>
      </div>
    </main>
  );
}

export default HomePage;
